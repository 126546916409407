<template>
  <main class="work">
    <div class="work-c">
      <section class="l-work-subsection align-center" id="executive-recruitment" data-scroll-section>
        <div class="main-features">
          <h1 class="title title-big title-general" data-scroll="fade-up" data-scroll-delay="1">The Work: Transaction Support</h1>
          <ul class="main-list">
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="1">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-client-profile.svg`)" /></figure>
                <h3 class="subtitle">Client Profile</h3>
                <div class="text-c">
                  <p class="text">&bull; A Private Equity firm with branch operations across multiple European and Middle Eastern markets</p>
                  <p class="text">&bull; Three active funds with nearly US $6 billion AUM</p>
                  <p class="text">&bull; Over 80 portfolio companies and a successful, ten -fund track record</p>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="2">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-client-situation.svg`)" /></figure>
                <div class="text-c">
                  <h3 class="subtitle">Client Situation</h3>
                  <div class="text-c">
                    <p class="text">The client bought an established retail food business with over 50 stores across multiple cities, injecting capital into the company, and recruiting a management team to improve performance through a complex series of real estate transactions.</p>
                    <p class="text">When real estate prices unexpectedly fell, the financial results plummeted, resulting in near-total loss of capital.</p>
                    <p class="text">Core question: given the situation, should the PE owner continue to back its management team and stay in the deal?</p>
                  </div>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="3">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-diagnostic-finding.svg`)" /></figure>
                <div class="text-c">
                  <h3 class="subtitle">Diagnostic Finding</h3>
                  <div class="text-c">
                    <p class="text">Using our proprietary Diagnostic Process, Ascentador was able to "get under the skin" of the organization and highlight specific risks against the backdrop of previously unknown micro-and macro-factors that had undermined business results.</p>
                    <p class="text">These findings allowed the PE to recalculate the risk equation, take corrective action to restore capital to the business, and to double down on its confidence in the management team, grounded in data.</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="bespoke-solution align-center">
          <h1 class="title title-small title-general" data-scroll="fade-up" data-scroll-delay="1"><span>Ascentador</span>Bespoke Solution</h1>
          <h3 class="highlight" data-scroll="fade-up" data-scroll-delay="2">Given the critical nature of the decision facing our PE client concerning their massive investment in this business, Ascentador provided specific predictive insight, allowing the client to assess the risk of staying in the deal and supporting the current management team. The core attributes we examined included:</h3>
          <ul class="main-list four-items">
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="1">
              <div class="c-card">
                <span class="number"><img alt="exchange" :src="require(`@/assets/work/icon-bespoke-01.svg`)"/></span>
                <h3 class="subtitle">Ability</h3>
                <div class="text-c">
                  <p class="text">Including everything from leadership to core functional skills as well as developmental needs.</p>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="2">
              <div class="c-card">
                <span class="number"><img alt="exchange" :src="require(`@/assets/work/icon-bespoke-02.svg`)"/></span>
                <div class="text-c">
                  <h3 class="subtitle">Aspiration</h3>
                  <div class="text-c">
                    <p class="text">Focusing on each executive’s desire to advance and influence his/ her “world of work”, including insight about the importance of financial rewards and other “invisible” motivators.</p>
                  </div>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="3">
              <div class="c-card">
                <span class="number"><img alt="exchange" :src="require(`@/assets/work/icon-bespoke-03.svg`)"/></span>
                <div class="text-c">
                  <h3 class="subtitle">Engagement</h3>
                  <div class="text-c">
                    <p class="text">Providing insight into the beliefs of the executive about the company, his/her commitment to stay, and willingness to contribute above and beyond the call of duty.</p>
                  </div>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="4">
              <div class="c-card">
                <span class="number"><img alt="exchange" :src="require(`@/assets/work/icon-bespoke-04.svg`)"/></span>
                <div class="text-c">
                  <h3 class="subtitle">Team Complementarity</h3>
                  <div class="text-c">
                    <p class="text">Revealing how individual members of the team “activate” each other for enhanced individual productivity and achievement of business outcomes.</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'TransactionSupport',
  metaInfo() {
      return { 
          title: "Ascentador | The Work",
          meta: [
              { name: 'description', content:  'The Diagnostic Process brings together a team of expert consultants and key client stakeholders prior to launching any project. Using a proprietary form of guided questioning, the goal is to gain insight, uncover a lack of alignment and reveal unspoken agendas before laying the groundwork for the development of lateral solutions.'},
          ]
      }
  },
  data() {
    return {};
  },
  mounted() {
    this.$emit('view-mounted');
  },
};
</script>
